import { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Scroll, ScrollControls } from "@react-three/drei";
import { MotionConfig } from "framer-motion";
import { framerMotionConfig } from "./assets/config";
import { ScrollManager } from "./components/ScrollManager";
import { Scene } from "./components/Scene";
import { Interface } from "./components/Interface";
import { Menu } from "./components/page-components/menu/menu";
import { useSection } from './contexts/SectionContext';
import { Navbar } from './components/page-components/navbar/navbar';
import LoadingScreen from "./components/page-components/loading/loading";
import { SidePanel } from "./components/page-components/side-panel/side-panel";
import ContactContent from "./components/page-components/contact-content/contact-content";
import CV from "./components/page-components/cv/cv";
import ProjectsModal from "./components/page-components/projects-modal/projects-modal";
import ImgFullScreenSlider from "./components/page-components/img-full-screen-slider/img-full-screen-slider";

function App() {
  const {
    section, screenWidth,
    isConnectModalOpen,
    cvModalOpen,
    projectModalOpen, setProjectModalOpen, 
    selectedProject, setSelectedProject,
    fullscreenOpen, setFullscreenOpen,
    setSelectedImageIndex, 
    imagesForSlider, setImagesForSlider,
  } = useSection();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3700);

    return () => clearTimeout(timer);
  }, []);

  const getClassName = (section) => {
    switch (section) {
      case 0:
        return 'main main-section0';
      case 1:
        return 'main main-section1';
      default:
        return 'main';
    }
  };

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }

  const openProjectModal = (project) => {
    setSelectedProject(project); 
    setProjectModalOpen(true);
  };

  const closeProjectModal = () => {
    setProjectModalOpen(false); 
  };

  const openImgSlider = (images, index) => {
    console.log('Opening slider with images:', images);
    setImagesForSlider(images);
    setSelectedImageIndex(index);
    setFullscreenOpen(true);
  };

  const closeImgSlider = () => {
    setFullscreenOpen(false); 
  };

  return (
    <div id="main" className={getClassName(section)}>
      <MotionConfig transition={{ ...framerMotionConfig }}>
        <Canvas 
          // pixelratio={Math.min(0.000000000000001, window.devicePixelRatio * 0.000000000000001)}  // pixelratio={isHighResolution ? 0.25 : window.devicePixelRatio}
          shadows={false} 
          camera={{ position: [0, 2, 5], fov: 30 }}
        >
          <Scene/>
          <ScrollControls pages={5} damping={0.1}>
            <ScrollManager/>
              <Scroll html>
                <Interface 
                  currentSection={section} 
                  screenWidth={screenWidth} 
                  openProjectModal={openProjectModal} 
                />
              </Scroll>
          </ScrollControls>
        </Canvas>
      </MotionConfig>
      
      

      <Navbar/>
      <Menu/>
      <SidePanel/>

      {isConnectModalOpen && (
        <ContactContent closeModal={true}/>
      )}

      {cvModalOpen && (
        <CV />
      )}

      {projectModalOpen && (
        <ProjectsModal project={selectedProject} onClose={closeProjectModal} openImgSlider={openImgSlider} />
      )}

      {fullscreenOpen && (
        <ImgFullScreenSlider
          images={imagesForSlider}
          onClose={closeImgSlider}
        />
      )}

      {isLoading && (
        <LoadingScreen />
      )}

    </div>
  );
}

export default App;
