import * as images from './ProjectImg';

export const projects1 = [
  { 
    imgs: [images.foodApp1, images.foodApp2, images.foodApp3],
    link: "http://test8.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Food_App_Form_Scratch/tree/main",
    title: 'Food App', 
    description: 'This app is mix of Instagram about food and app for calculating calories / creating diet.', 
    description_detailed: 'This app is mix of Instagram about food and app for calculating calories / creating diet.', 
    hashtags: ['#FromScratch', '#React', '#Firebase'] 
  },
  { 
    imgs: [images.bank1, images.bank2, images.bank3],
    link: "http://www.test.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Web_Bank_App_From_Scratch",
    title: 'Web Bank', 
    description: 'Web bank where users can add funds to balance and send funds to other bank users.', 
    description_detailed: 'Web bank where users can add funds to balance and send funds to other bank users.', 
    hashtags: ['#FromScratch', '#React', '#Firebase',] 
  },
  { 
    imgs: [images.clipit1, images.clipit2, images.clipit3],
    link: "http://www.test7.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Angular_Learning_ZTM_2",
    title: 'ClipIt', 
    description: 'The #1 app to watch, clip, and share your awesome gaming moments with your friends and the world!', 
    description_detailed: 'The #1 app to watch, clip, and share your awesome gaming moments with your friends and the world! With our world-class tools, we make it incredibly easy to share your videos. Get started today!', 
    hashtags: ['#Angular', '#TypeScript', '#Firebase',] 
  },
  { 
    imgs: [images.webShop1, images.webShop2, images.webShop3],
    link: "http://test4.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/WebShop",
    title: 'Web Shop', 
    description: 'This is web shop with catalog of items, account creation and skrill payments.', 
    description_detailed: 'This is web shop with catalog of items, account creation and skrill payments.', 
    hashtags: ['#React', '#Redux', '#Firebase']  
  },
  { 
    imgs: [images.marvel1, images.marvel2, images.marvel3,],
    link: "http://test2.alexanackis.com/#",
    linkGitHub: "https://github.com/anackis/Marvel-Wiki",
    title: 'Marvel', 
    description: 'This is Marvell wiki with connection to Marvel Api for searching Marvell characters information.', 
    description_detailed: 'This is Marvell wiki with connection to Marvel Api for searching Marvell characters information.', 
    hashtags: ['#React', '#UsingApi', '#FSM'] 
  },
  { 
    imgs: [images.artGallery1, images.artGallery2, images.artGallery3,],
    link: "http://test6.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/art-gallery",
    title: 'Art Gallery', 
    description: 'This application is a demonstration of integrating external APIs into a React-based project, specifically using the Rijksmuseum API. ', 
    description_detailed: 'This application is a demonstration of integrating external APIs into a React-based project, specifically using the Rijksmuseum API. The API provides access to a rich repository of art and metadata which has been leveraged here to create a dynamic and responsive art gallery application.', 
    hashtags: ['#React', '#Rijksmuseum API', '#TypeScript', ] 
  },
  { 
    imgs: [images.runsmart1, images.runsmart2, images.runsmart3,],
    link: "http://test1.alexanackis.com/#promo",
    linkGitHub: "https://github.com/anackis/ProjectNr2",
    title: 'Run Smart', 
    description: 'Webpage for selling sport gear. Have features as sliders, form sending and Google Map Api.', 
    description_detailed: 'Webpage for selling sport gear. Have features as sliders, form sending and Google Map Api.', 
    hashtags: ['#JavaScript', '#Using Api', '#Gulp'] 
  },
  { 
    imgs: [images.pineapple1, images.pineapple2, images.pineapple3,],
    link: "http://test3.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Junior-Mid-recruiting-task",
    title: 'Pineapple', 
    description: 'This is recruiting task. Form page with validation.', 
    description_detailed: 'This is recruiting task. Form page with validation.', 
    hashtags: ['#React', '#PixelPerfect', '#Formik+Yup'] 
  },
];

export const projects2 = [
  { 
    imgs: [images.oldportfolio1, images.oldportfolio2, images.oldportfolio3],
    linkGitHub: "https://github.com/anackis/Portfolio",
    title: 'Portfolio', 
    description: 'Old Portfolio on React with adaptive design, sliders and email send form.', 
    description_detailed: 'Old Portfolio on React with adaptive design, sliders and email send form.', 
    hashtags: ['#React', '#EmailJs', '#Responsive'] 
  },
  { 
    imgs: [images.heroes1, images.heroes2, images.heroes3,],
    linkGitHub: 'https://github.com/anackis/Heroes-Creator-Filter',
    title: 'Heroes Creator',
    description: 'Creation form with filter for practicing Redux and Json Server.',
    description_detailed: 'Creation form with filter for practicing Redux and Json Server.',
    hashtags: ['#React','#Redux', '#JsonServer'] 
  },
  { 
    imgs: [images.food1, images.food2, images.food3,],
    linkGitHub: 'https://github.com/anackis/Food-Store',
    title: 'Web Food',
    description: 'Beautiful website about food with calories calculator built with Javascript.',
    description_detailed: 'Beautiful website about food with calories calculator built with Javascript.',
    hashtags: ['#JavaScript', '#JSON'] 
  },
  { 
    imgs: [images.adminPanel, images.adminPanel, images.adminPanel,],
    linkGitHub: 'https://github.com/anackis/Workers-Admin-Panel',
    title: 'Admin Panel',
    description: 'Admin panel for workers management for practicing React Hooks and Components.',
    description_detailed: 'Admin panel for workers management for practicing React Hooks and Components.',
    hashtags: ['#React', '#ReactHooks/Components'] 
  },
  { 
    imgs: [images.blog1, images.blog2, images.blog3,],
    linkGitHub: 'https://github.com/anackis/Project_1.13_Blog_V2',
    title: 'Web Blog',
    description: 'Blog project with post creation for practicing EJS and MongoDb.',
    description_detailed: 'Blog project with post creation for practicing EJS and MongoDb.',
    hashtags: ['#EJS', '#MongoDb'] 
  },
  { 
    imgs: [images.productList1, images.productlist2, images.productlist3,],
    linkGitHub: "https://github.com/anackis/Products-List_and_Add-Product-Panel",
    title: 'Product List', 
    description: 'This is recruiting task. Products list with admin panel.', 
    description_detailed: 'This is recruiting task. Products list with admin panel.', 
    hashtags: ['#React', '#PHP', '#MySql'] 
  },
  { 
    imgs: [images.losangelesMountains1, images.losangelesMountains2, images.losangelesMountains3,],
    link: "http://test5.alexanackis.com/",
    linkGitHub: "https://github.com/anackis/Losangeles_mountains-Junior-Recruiting_Test-",
    title: 'L.A. Mountains', 
    description: 'This is recruiting task. Landing page about Los Angeles Mountains.', 
    description_detailed: 'This is recruiting task. Landing page about Los Angeles Mountains.', 
    hashtags: ['#React', '#ReactHooks', '#Sass'] 
  },
  { 
    imgs: [images.form1, images.form2, images.form1,],
    linkGitHub: 'https://github.com/anackis/Small-Form-Example-',
    title: 'Form',
    description: 'Form for practicing Formik, Yup and own validation.',
    description_detailed: 'Form for practicing Formik, Yup and own validation.',
    hashtags: ['#Formik', '#Yup ', '#OwnValidation'] 
  },
];
