import { useState, useEffect } from 'react';
import { Section } from '../section';

const SkillsSection = () => {
  const [blockHeight, setBlockHeight] = useState('80vh'); 

  useEffect(() => {
    function updateHeight() {
      const vh = window.innerHeight * 0.01;
      setBlockHeight(window.innerWidth < 991 ? `${vh * 70}px` : `${vh * 80}px`);
    }

    updateHeight(); 
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight); 
    };
  }, []);

  return (
    <Section id="skills__section">
      <div className="about block" style={{ height: blockHeight }}>
        <div className="section-about-wrapper">
          <div className="section__header">
            <div className="section__text section__text_normal fz-header">Skills</div>
          </div>
          <div className="bubble_wrap">
            <p className='fz-text scrollable-text'>
              <span className='block_sub-title'>Frontend/Backend Development</span>
              <span className='block_sub-text'>Proficient in Angular, React, Next.js, and NestJs, with a strong ability to integrate responsive front-end interfaces and robust backend solutions.</span>

              <span className='block_sub-title'>Microservices & Containerization</span>
              <span className='block_sub-text'>Advanced knowledge of Docker, Kubernetes, and Helm, facilitating scalable and efficient deployments.</span>
              
              <span className='block_sub-title'>Database Management</span>
              <span className='block_sub-text'>Experienced in managing and optimizing databases with tools like MySQL, MongoDB, MySQL Workbench, and MongoDB Compass.</span>
              
              <span className='block_sub-title'>APIs & Version Control</span>
              <span className='block_sub-text'>Skilled in utilizing REST APIs and RxJs, with extensive experience in version control systems like Git and GitLab.</span>
              
              <span className='block_sub-title'>Management</span>
              <span className='block_sub-text'>Proven track record in leading development teams and handling project management tasks, including serving as a Scrum Master.</span>
              
              <span className='block_sub-title'>Communication</span>
              <span className='block_sub-text'>Highly effective communicator, adept at fostering teamwork and engaging diverse teams towards common goals.</span>
              
              <span className='block_sub-title'>Problem-Solving</span>
              <span className='block_sub-text'>Robust analytical skills with a knack for innovative problem-solving and adaptability in fast-paced environments.</span>
              
              <span className='block_sub-title'>Tools and Technologies</span>
              <span className='block_sub-text'>I am well-versed in a modern and relevant tech stack that includes Angular, NextJs, RxJs, REST API, Docker, Git, React, TypeScript, single-spa, NestJs, Kubernetes, Helm, Keycloak, GitLab, and Asana and some others.</span>
              
            </p>
          </div>
        </div>
      </div>

      {/* <div className="area">
        <ul className="circles">
          <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
        </ul>
      </div> */}
    </Section>
  )
}

export default SkillsSection
