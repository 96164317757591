import HomeSection from './page-components/home-section/home-section';
import AboutSection from './page-components/about-section/about-section';
import SkillsSection from './page-components/skills-section/skills-section';
import ProjectsSection from './page-components/projects-section/projects-section';
import ContactSection from './page-components/contact-section/contact-section';

export const Interface = ({ currentSection, screenWidth, openProjectModal}) => {
  return (
    <div className='interface-fix'>
      <HomeSection />
      <AboutSection />
      <SkillsSection />
      <ProjectsSection 
        currentSection={currentSection} 
        screenWidth={screenWidth}
        openProjectModal={openProjectModal}
      />
      <ContactSection />
    </div>
  );
}
